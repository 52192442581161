.tile {
  background-color: @c4;
  border-top: @xs solid @c2;
  box-shadow: 0px 7px 9px -2px rgba(black, .5);
  display: flex;
  flex-direction: column;
  padding: @m;
  position: relative;
  text-align: center;
  transition: border-top @transitionSpeedA ease;
  transition-property: border-top, background-color;

  &:hover {
    background-color: @c2;
    border-color: @c1;
  }

  &__img {
    margin: 0 auto;
    height: @xl;
    width: @xl;;
  }

  &__title {
    .title--s();
    margin: @m 0;
  }

  .button {
    width: 100%;
  }

  & + .tile {
    margin-top: @l;
  }

  .break(m; {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      margin-top: @l;
      width: calc(50% - @xs);

      .button {
        margin-top: auto;
      }
    }
  });

  .break(l; {
    &__item {
      width: calc(25% - @s);
    }
  });
}