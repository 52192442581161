// styling for table block
.table {
  display: flex;

  .block__wrapper {
    width: 100%;
  }

  .block__content {
    overflow: auto;
  }

  .table {
    min-width: 800px;
  }

  .column {
    border: 1px solid @c8;
    color: @c1;
    width: 100%;

    & + .column {
      border-left: 0;
    }

    > span {
      display: block;
      font-weight: 700;
      padding: @s;
    }
  }

  .cell {
    border-top: 1px solid @c8;
    color: @c1;
    padding: @s;
  }

  .break(m; {
    .table {
      min-width: 0;
    }
  });
}