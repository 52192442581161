.main__wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &--media {
    .spread();
    top: -@l;
    z-index: -1;
  }

  > .block {
    width: 100%;
  }
}