.header {
  padding-bottom: @xl;

  .block__title {
    .title--m();
    color: @c1;
    text-align: center;
  }

  .text {
    color: @c1;
    text-align: center;
    font-size: 1.9rem;
  }

  .block__content--media {
    bottom: 0;
    height: @xxxl;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;

    picture {
      .spread();
      overflow: hidden;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .tile {
    &__list {
      margin-top: @m;
    }
  }

  .break(m; {
    padding-bottom: @xxxl;

    .block__content--media {
        min-height: calc(@xxxl * 2);
    }

    .button {
      pointer-events: none;
    }
  })
}

.header--plain {
  height: 200px;
  overflow: hidden;

  .block__wrapper--media {
    margin: 0 auto;
    max-width: @max-width--wide;

    img {
      height: auto;
      min-height: 200px;
      width: 100%;
    }
  }

  .break(m; {
    height: 420px;
    margin-bottom: -240px;

    & ~ .block {
      padding: 0;

      .block__wrapper {
        background-color: @c4;
        max-width: @max-width;
        padding: @xxl @xxxl 0 @xxxl;
      }

      &.page-title .block__wrapper {
        padding-bottom: @m;
      }

      &:last-child {
        margin-bottom: @xxl;
      }
    }

    .block__wrapper--media {
      img {
        height: auto;
        width: 100%;
      }
    }
  });
}

.header--no-image {
  img {
    display: none;
  }
}