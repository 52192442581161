// styling for reset
* {
  box-sizing: border-box;
}

:root {
  font-size: 10px;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

a {
  color: @c5;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  width: 100%;
}