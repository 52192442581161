.tips {
  &__list {
    margin-top: @m;
  }

  &__item {
    display: flex;
    justify-content: space-between;

    .text {
      color: @c1;
      font-weight: 600;
      padding: @xs 0;
      width: calc(100% - (@xxl + @s));
    }

    & + & {
      margin-top: @xxs;
    }
  }

  &--alt-view {
  background-color: @c6;
  margin-top: @xl;

    &.block > .block__wrapper {
      background-color: @c6;
      max-width: @max-width--wide * 1.2;
      padding-bottom: @xl;
    }
  }
}