// styling for collapsible content
//
// uses ../js/helper/collapsibleHelper.js
[data-collapsible="content"] {
  height: 0;
  overflow: hidden;
  transition: opacity @transitionSpeedA ease, height .25s ease;
}

[data-collapsible="trigger"] {
  cursor: pointer;

  > * {
    pointer-events: none;
  }

  .icon {
    transition: transform @transitionSpeedA ease;
  }
}

.calculator [data-collapsible="close"] .icon {
  transform: rotate(0deg);
}

[class*="--triggered"] {
  [data-collapsible="trigger"],
  [data-collapsible="open"] {
    .icon {
      transform: rotate(180deg);
    }
  }
}