// styling for blocks
.block {
  padding: @m 0;
  position: relative;

  &--alt-bg {
    background-color: @c6;
  }

  &__wrapper {
    max-width: @max-width--wide;
    padding: 0 1.5 * @xs;
  }

  &__wrapper--media {
    .spread();
    z-index: -1;

    picture {
      .spread();
      overflow: hidden;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .break(m; {
    &__wrapper {
      margin: 0 auto;
    }

    &.block.block--side-by-side {
      margin-left: auto;
      width: calc(@max-width--wide / 2);

      & + .block--side-by-side {
        margin-left: 0;
        margin-right: auto;
      }
    }
  });
}