.center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.center--vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.center--horizontal {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.spread {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.stripes {
  background-image: @stripe;
  background-position: top left;
  background-repeat: repeat-x;
  filter: invert(69%) sepia(60%) saturate(0%) hue-rotate(215deg) brightness(108%) contrast(99%);
  height: 12px;
  padding-top: @xs;
  position: absolute;
  width: 100%;

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: -@xxs;
  }
}