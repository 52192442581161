.footer {

  text-align: center;

  .copyright {
    color: @c7;
    font-size: 1.7rem;
    font-weight: 700;
  }

  &__bottom-list {
    margin-top: @xl;
  }

  &__item {
    margin-top: @s;
  }

  .link {
    color: @c8;
    font-size: 1.7rem;
    font-weight: 400;
  }

  .break(m; {
    &__bottom-list, 
    &__top-list {
      display: flex;
      justify-content: center;
      margin-top: 0;
    }

    &__item + &__item {
      margin-left: @m;
    }
  });
}