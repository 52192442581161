// styling for typography
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600;700&display=swap');

body {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 30px;
}

.title--xxs {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: @s 0;
}

.title--xs {
  color: @c1;
  font-size: 1.9rem;
  font-weight: 600;
  margin: @s 0;
}

.title--s {
  color: @c1;
  font-size: 2.5rem;
  font-weight: 600;
  margin: @s 0;
}

.title--m {
  color: @c1;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 3.5 * @sbase;
  margin: @s 0;
}

.title--l {
  color: @c10;
  font-size: 5rem;
  font-weight: 600;
  line-height: @xxl;
  margin: @s 0;
}