// styling for buttons
.button {
  display: inline-block;
  font-size: 1.7rem;
  padding: @xxs @m @xxs @s;
  text-decoration: none;
  transition: background-color @transitionSpeedA ease;
  transition-property: background-color, color;
  width: auto;

  &--prim {
    background-color: @c1;
    color: @c2;

    &:hover {
      background-color: @c2;
      color: @c1;
    }
  }

  &--icon {
    display: inline-flex;
    position: relative;
    justify-content: center;

    .icon {
      margin-left: @xxs;
      position: static;
      transform: translateY(@xxs);
      width: @sbase;
    }

    &:hover .icon {
      color: @c1;
    }
  }
}

.only-button {
  .block__content {
    text-align: center;
  }
}