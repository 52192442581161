// styling for navigation
.navigation {
  padding: @xxs 0 0;

  &__item {
    position: relative;

    .link {
      .spread();
    }
  }

  &__top {
    .block__content {
      display: flex;
      justify-content: flex-end;
      padding: @xxs 0;
    }

    .navigation__item {
      .title--xxs();
      color: @c1;
      margin: 0;

      span {
        font-size: 1.3rem;
        transition: color @transitionSpeedA ease;
      }

      .link:hover + span {
        color: @c9;
      }

      & + .navigation__item {
        margin-left: @m;
      }
    }
  }

  &__middle {
    background-color: @c1;

    .logo {
      display: block;
      max-width: 480px;
    }


    .block__content {
      padding: 1.5 * @xs 0;
    }
  }

  &__bottom {
    background-color: @c2;
    box-shadow: 0 0 3px 1px rgba(black, .5);

    .block__wrapper {
      min-height: @xxl;
      padding: @s @s 0 @s;
    }

    .block__content {
      height: 0;
    }

    .hamburger {
      margin-bottom: @xs;
    }

    .navigation__item {
      .title--xs();
      border-left: 3px transparent solid;
      margin: @xs 0;
      padding: @xs @xs;
      position: relative;
      transition: border-color @transitionSpeedA ease; 
    }

    .navigation__item:hover {
      border-color: @c4;
    }

    .navigation__item.current_page_item {
      border-color: @c1;
    }
  }

  .break(m; {
    &__bottom {
      .hamburger {
        display: none;
      }

      .block__wrapper {
        padding-top: 0;
      }

      .block__content {
        display: flex;
        height: auto;
      }

      .navigation__item {
        border-bottom: 4px transparent solid;
        border-left: 0;
        margin: 0;
        padding: @s @xs;
      }
    }
  })
}