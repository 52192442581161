.icon {
  color: @c2;
  height: @s;
  width: @s;

  svg {
    fill: currentColor;
    height: 100%;
    width: 100%;
  }
}