.alinea {
  color: @c1;

  &--centered {
    text-align: center;
  }

  h2 {
    .title--m();
    color: @c10;
    margin-bottom: @m;
  }

  h2.mb-3 {
    .title--s();
    margin-bottom: @m;
  }

  h3 {
    .title--s();
    margin-bottom: @m;
  }

  p + p {
    margin-top: @m;
  }

  a {
    .link();
  }

  ul {
    list-style-type: disc;
    margin: @s 0;
    padding-left: @l;
  }

  .button {
    width: auto;
  }
}