.page-title {
  .block__header {
    position: relative;

    &::before {
      background-color: @c10;
      bottom: -@xl;
      content: '';
      display: block;
      height: 4px;
      position: absolute;
      width: 200px;
    }
  }
}