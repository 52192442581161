// styling for hamburger
.hamburger {
  display: flex;
  flex-direction: column;
  height: @xs;
  justify-content: space-between;
  width: @s;

  &--part {
    background-color: @c3;
    border-radius: .25 * @xxs;
    height: .3 * @xxs;
    width: 100%;
  }
}