.center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.center--vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.center--horizontal {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.spread {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.stripes {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20"><polyline points="0,10.4 10.8,0 19.9,0 8.7,10.4 0,10.4 "/></svg>');
  background-position: top left;
  background-repeat: repeat-x;
  filter: invert(69%) sepia(60%) saturate(0%) hue-rotate(215deg) brightness(108%) contrast(99%);
  height: 12px;
  padding-top: 1rem;
  position: absolute;
  width: 100%;
}
.stripes--top {
  top: 0;
}
.stripes--bottom {
  bottom: -0.5rem;
}
.aspect-ratio {
  overflow: hidden;
  position: relative;
}
.aspect-ratio img {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}
.aspect-ratio iframe {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
.aspect-ratio--portrait img {
  height: 100%;
  width: auto;
}
.aspect-ratio::after {
  content: '';
  display: block;
}
.aspect-ratio--2-39-1::after {
  padding-top: 32.25%;
}
.aspect-ratio--21-9::after {
  padding-top: 40%;
}
.aspect-ratio--1-1::after {
  padding-top: 100%;
}
.aspect-ratio--16-9::after {
  padding-top: 56.25%;
}
.aspect-ratio--4-3::after {
  padding-top: 75%;
}
.aspect-ratio--3-2::after {
  padding-top: 66.66%;
}
.aspect-ratio--8-5::after {
  padding-top: 62.5%;
}
.aspect-ratio--2-3::after {
  padding-top: 110%;
}
* {
  box-sizing: border-box;
}
:root {
  font-size: 10px;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
a {
  color: #0275d8;
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
img {
  width: 100%;
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v22/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_AkA.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v22/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v22/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v22/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkA.ttf) format('truetype');
}
body {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 30px;
}
.title--xxs {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 2rem 0;
}
.title--xs {
  color: #2a3770;
  font-size: 1.9rem;
  font-weight: 600;
  margin: 2rem 0;
}
.title--s {
  color: #2a3770;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 2rem 0;
}
.title--m {
  color: #2a3770;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 3.5rem;
  margin: 2rem 0;
}
.title--l {
  color: #80aedd;
  font-size: 5rem;
  font-weight: 600;
  line-height: 6rem;
  margin: 2rem 0;
}
.button {
  display: inline-block;
  font-size: 1.7rem;
  padding: 0.5rem 3rem 0.5rem 2rem;
  text-decoration: none;
  transition: background-color 0.25s ease;
  transition-property: background-color, color;
  width: auto;
}
.button--prim {
  background-color: #2a3770;
  color: #ffdd00;
}
.button--prim:hover {
  background-color: #ffdd00;
  color: #2a3770;
}
.button--icon {
  display: inline-flex;
  position: relative;
  justify-content: center;
}
.button--icon .icon {
  margin-left: 0.5rem;
  position: static;
  transform: translateY(0.5rem);
  width: 1rem;
}
.button--icon:hover .icon {
  color: #2a3770;
}
.only-button .block__content {
  text-align: center;
}
.circle {
  background-color: #edf3fb;
  border-radius: 50%;
  font-size: 2.7rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  width: 5rem;
}
.hamburger {
  display: flex;
  flex-direction: column;
  height: 1rem;
  justify-content: space-between;
  width: 2rem;
}
.hamburger--part {
  background-color: black;
  border-radius: 0.125rem;
  height: 0.15rem;
  width: 100%;
}
.icon {
  color: #ffdd00;
  height: 2rem;
  width: 2rem;
}
.icon svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
}
.link {
  text-decoration: none;
}
.link:hover {
  color: #014c8c;
  text-decoration: underline;
}
.tile {
  background-color: white;
  border-top: 1rem solid #ffdd00;
  box-shadow: 0px 7px 9px -2px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  padding: 3rem;
  position: relative;
  text-align: center;
  transition: border-top 0.25s ease;
  transition-property: border-top, background-color;
}
.tile:hover {
  background-color: #ffdd00;
  border-color: #2a3770;
}
.tile__img {
  margin: 0 auto;
  height: 5rem;
  width: 5rem;
}
.tile__title {
  color: #2a3770;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 2rem 0;
  margin: 3rem 0;
}
.tile .button {
  width: 100%;
}
.tile + .tile {
  margin-top: 4rem;
}
@media only screen and (min-width: 768px) {
  .tile__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .tile__item {
    margin-top: 4rem;
    width: calc(50% - 1rem);
  }
  .tile__item .button {
    margin-top: auto;
  }
}
@media only screen and (min-width: 992px) {
  .tile__item {
    width: calc(25% - 2rem);
  }
}
.block {
  padding: 3rem 0;
  position: relative;
}
.block--alt-bg {
  background-color: #edf3fb;
}
.block__wrapper {
  max-width: 1140px;
  padding: 0 1.5rem;
}
.block__wrapper--media {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.block__wrapper--media picture {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}
.block__wrapper--media img {
  height: 100%;
  width: auto;
}
@media only screen and (min-width: 768px) {
  .block__wrapper {
    margin: 0 auto;
  }
  .block.block.block--side-by-side {
    margin-left: auto;
    width: calc(1140px / 2);
  }
  .block.block.block--side-by-side + .block--side-by-side {
    margin-left: 0;
    margin-right: auto;
  }
}
.main__wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.main__wrapper--media {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  top: -4rem;
  z-index: -1;
}
.main__wrapper > .block {
  width: 100%;
}
[data-collapsible="content"] {
  height: 0;
  overflow: hidden;
  transition: opacity 0.25s ease, height 0.25s ease;
}
[data-collapsible="trigger"] {
  cursor: pointer;
}
[data-collapsible="trigger"] > * {
  pointer-events: none;
}
[data-collapsible="trigger"] .icon {
  transition: transform 0.25s ease;
}
.calculator [data-collapsible="close"] .icon {
  transform: rotate(0deg);
}
[class*="--triggered"] [data-collapsible="trigger"] .icon,
[class*="--triggered"] [data-collapsible="open"] .icon {
  transform: rotate(180deg);
}
.alinea {
  color: #2a3770;
}
.alinea--centered {
  text-align: center;
}
.alinea h2 {
  color: #2a3770;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 3.5rem;
  margin: 2rem 0;
  color: #80aedd;
  margin-bottom: 3rem;
}
.alinea h2.mb-3 {
  color: #2a3770;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 2rem 0;
  margin-bottom: 3rem;
}
.alinea h3 {
  color: #2a3770;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 2rem 0;
  margin-bottom: 3rem;
}
.alinea p + p {
  margin-top: 3rem;
}
.alinea a {
  text-decoration: none;
}
.alinea a:hover {
  color: #014c8c;
  text-decoration: underline;
}
.alinea ul {
  list-style-type: disc;
  margin: 2rem 0;
  padding-left: 4rem;
}
.alinea .button {
  width: auto;
}
.footer {
  text-align: center;
}
.footer .copyright {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.7rem;
  font-weight: 700;
}
.footer__bottom-list {
  margin-top: 5rem;
}
.footer__item {
  margin-top: 2rem;
}
.footer .link {
  color: rgba(0, 0, 0, 0.41);
  font-size: 1.7rem;
  font-weight: 400;
}
@media only screen and (min-width: 768px) {
  .footer__bottom-list,
  .footer__top-list {
    display: flex;
    justify-content: center;
    margin-top: 0;
  }
  .footer__item + .footer__item {
    margin-left: 3rem;
  }
}
.header {
  padding-bottom: 5rem;
}
.header .block__title {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 3.5rem;
  margin: 2rem 0;
  color: #2a3770;
  text-align: center;
}
.header .text {
  color: #2a3770;
  text-align: center;
  font-size: 1.9rem;
}
.header .block__content--media {
  bottom: 0;
  height: 8rem;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.header .block__content--media picture {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}
.header .block__content--media img {
  height: 100%;
  width: auto;
}
.header .tile__list {
  margin-top: 3rem;
}
@media only screen and (min-width: 768px) {
  .header {
    padding-bottom: 8rem;
  }
  .header .block__content--media {
    min-height: calc(8rem * 2);
  }
  .header .button {
    pointer-events: none;
  }
}
.header--plain {
  height: 200px;
  overflow: hidden;
}
.header--plain .block__wrapper--media {
  margin: 0 auto;
  max-width: 1140px;
}
.header--plain .block__wrapper--media img {
  height: auto;
  min-height: 200px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .header--plain {
    height: 420px;
    margin-bottom: -240px;
  }
  .header--plain ~ .block {
    padding: 0;
  }
  .header--plain ~ .block .block__wrapper {
    background-color: white;
    max-width: 960px;
    padding: 6rem 8rem 0 8rem;
  }
  .header--plain ~ .block.page-title .block__wrapper {
    padding-bottom: 3rem;
  }
  .header--plain ~ .block:last-child {
    margin-bottom: 6rem;
  }
  .header--plain .block__wrapper--media img {
    height: auto;
    width: 100%;
  }
}
.header--no-image img {
  display: none;
}
.navigation {
  padding: 0.5rem 0 0;
}
.navigation__item {
  position: relative;
}
.navigation__item .link {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.navigation__top .block__content {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;
}
.navigation__top .navigation__item {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 2rem 0;
  color: #2a3770;
  margin: 0;
}
.navigation__top .navigation__item span {
  font-size: 1.3rem;
  transition: color 0.25s ease;
}
.navigation__top .navigation__item .link:hover + span {
  color: #596cc1;
}
.navigation__top .navigation__item + .navigation__item {
  margin-left: 3rem;
}
.navigation__middle {
  background-color: #2a3770;
}
.navigation__middle .logo {
  display: block;
  max-width: 480px;
}
.navigation__middle .block__content {
  padding: 1.5rem 0;
}
.navigation__bottom {
  background-color: #ffdd00;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
}
.navigation__bottom .block__wrapper {
  min-height: 6rem;
  padding: 2rem 2rem 0 2rem;
}
.navigation__bottom .block__content {
  height: 0;
}
.navigation__bottom .hamburger {
  margin-bottom: 1rem;
}
.navigation__bottom .navigation__item {
  color: #2a3770;
  font-size: 1.9rem;
  font-weight: 600;
  margin: 2rem 0;
  border-left: 3px transparent solid;
  margin: 1rem 0;
  padding: 1rem 1rem;
  position: relative;
  transition: border-color 0.25s ease;
}
.navigation__bottom .navigation__item:hover {
  border-color: white;
}
.navigation__bottom .navigation__item.current_page_item {
  border-color: #2a3770;
}
@media only screen and (min-width: 768px) {
  .navigation__bottom .hamburger {
    display: none;
  }
  .navigation__bottom .block__wrapper {
    padding-top: 0;
  }
  .navigation__bottom .block__content {
    display: flex;
    height: auto;
  }
  .navigation__bottom .navigation__item {
    border-bottom: 4px transparent solid;
    border-left: 0;
    margin: 0;
    padding: 2rem 1rem;
  }
}
.page-title .block__header {
  position: relative;
}
.page-title .block__header::before {
  background-color: #80aedd;
  bottom: -5rem;
  content: '';
  display: block;
  height: 4px;
  position: absolute;
  width: 200px;
}
.table {
  display: flex;
}
.table .block__wrapper {
  width: 100%;
}
.table .block__content {
  overflow: auto;
}
.table .table {
  min-width: 800px;
}
.table .column {
  border: 1px solid rgba(0, 0, 0, 0.41);
  color: #2a3770;
  width: 100%;
}
.table .column + .column {
  border-left: 0;
}
.table .column > span {
  display: block;
  font-weight: 700;
  padding: 2rem;
}
.table .cell {
  border-top: 1px solid rgba(0, 0, 0, 0.41);
  color: #2a3770;
  padding: 2rem;
}
@media only screen and (min-width: 768px) {
  .table .table {
    min-width: 0;
  }
}
.tips__list {
  margin-top: 3rem;
}
.tips__item {
  display: flex;
  justify-content: space-between;
}
.tips__item .text {
  color: #2a3770;
  font-weight: 600;
  padding: 1rem 0;
  width: calc(100% - (6rem + 2rem));
}
.tips__item + .tips__item {
  margin-top: 0.5rem;
}
.tips--alt-view {
  background-color: #edf3fb;
  margin-top: 5rem;
}
.tips--alt-view.block > .block__wrapper {
  background-color: #edf3fb;
  max-width: 1368px;
  padding-bottom: 5rem;
}
