// aspect ratio manager
//
// aspect ratio  | padding-top value
// --------------|----------------------
// 1:1       |       100%
// 16:9      |       56.25%
// 4:3       |       75%
// 3:2       |       66.66%
// 8:5       |       62.5%
// 2:3       |       110%
// content
.aspect-ratio {
  overflow: hidden;
  position: relative;

  img {
    .center();
    height: auto;
    width: 100%;
  }

  iframe {
    .center();
    height: 100%;
    width: 100%;
  }

  &--portrait img {
    height: 100%;
    width: auto;
  }

  &::after {
    content: '';
    display: block;
  }

  &--2-39-1::after {
    padding-top: 32.25%;
  }

  &--21-9::after {
    padding-top: 40%;
  }

  &--1-1::after {
    padding-top: 100%;
  }

  &--16-9::after {
    padding-top: 56.25%;
  }

  &--4-3::after {
    padding-top: 75%;
  }

  &--3-2::after {
    padding-top: 66.66%;
  }

  &--8-5::after {
    padding-top: 62.5%;
  }

  &--2-3::after {
    padding-top: 110%;
  }
}